// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-for-colleges-organizations-js": () => import("./../../../src/pages/for-colleges-organizations.js" /* webpackChunkName: "component---src-pages-for-colleges-organizations-js" */),
  "component---src-pages-for-corporations-js": () => import("./../../../src/pages/for-corporations.js" /* webpackChunkName: "component---src-pages-for-corporations-js" */),
  "component---src-pages-for-students-js": () => import("./../../../src/pages/for-students.js" /* webpackChunkName: "component---src-pages-for-students-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-use-cases-basic-needs-support-js": () => import("./../../../src/pages/use-cases/basic-needs-support.js" /* webpackChunkName: "component---src-pages-use-cases-basic-needs-support-js" */),
  "component---src-pages-use-cases-eligibility-assessment-js": () => import("./../../../src/pages/use-cases/eligibility-assessment.js" /* webpackChunkName: "component---src-pages-use-cases-eligibility-assessment-js" */),
  "component---src-pages-use-cases-student-support-js": () => import("./../../../src/pages/use-cases/student-support.js" /* webpackChunkName: "component---src-pages-use-cases-student-support-js" */)
}

